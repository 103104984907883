@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --maxWidthContent: 800px;
}

@media only screen and (max-width: 576px) {
  #root {}
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

/* Extra space at end of task list, so that the bottom tasks is clearly visible */
[data-rbd-droppable-context-id] {
  padding-bottom: 100px;
}
