/* #editor img, .editor img {
  max-width: 100%;
  max-height: 226px;
  border-radius: 6px;
}

#editor img[uploadId], .editor img[uploadId] {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../gif/loading.gif");
}

#editor img[error], .editor img[error] {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../img/broken_image.png");
} */

.imagePluginRoot {
  border-radius: 6px !important;
}

.imagePluginRoot[imageplugin-align="left"] {
  width: unset !important;
  float: none !important;
  clear: both !important;
}
.imagePluginRoot[imageplugin-align="right"] {
  width: unset !important;
  float: none !important;
  clear: both !important;
}
.imagePluginRoot[imageplugin-align="center"] {
  width: unset !important;
  float: none !important;
  clear: both !important;
}

.imagePluginRoot[imageplugin-align="fullWidth"] {
  width: min-content !important;
  float: none !important;
  clear: both !important;
}

.alignRightButton,
.alignLeftButton,
.alignCenterButton,
.alignFullWidthButton {
  display: none !important;
}

.imageResizeBoxWrapper {
  position: absolute !important;
  width: default;
  display: block !important;
  justify-content: flex-start !important;
}

.imagePluginRoot img {
  align-self: start !important;
  border-radius: 6px !important;
}

placeholder {
  color: unset;
  /* position: unset;
  top: unset; */
  display: inline-block;
  width: 64px;
  height: 64px;
  border-radius: 6px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("loading.gif");
}

placeholder:after {
  content: "" !important;
}

.imageResizeBoxControl {
    display: none;
}

.imageResizeBoxControl.bottomRight {
    display: inline-block;
    cursor: e-resize;
    margin-top: -5px;
    right: 0;
    top: 50%;
}

.imageResizeBoxControl.bottomLeft {
  display: none !important;
  pointer-events: none;
}

.imageResizeBoxControl::after {
    background-color: #058FEF !important;
    border: solid 1px #fff;
    box-sizing: border-box;
    border-radius: 3px;
    content: "";
    height: 50px;
    position: absolute;
    top: -20px;
    width: 10px;

    box-shadow: 0px 2px 6px rgba(27, 33, 36, 0.12), 0px 1px 2px rgba(27, 33, 36, 0.08);
}

.imageResizeBox {
    background-repeat: no-repeat;
    box-sizing: border-box;
    left: 0;
    position: relative;
    top: 0;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
    box-shadow: unset !important;
    align-self: center;
    border: 1px solid #058FEF;
    border-radius: 8px;
}

